import React, { ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/abhaya-libre";
import "@fontsource/roboto";
import "@fontsource/yeseva-one";

import { ReactComponent as BG1 } from "~/images/lemonade-bg-1.svg";
import { ReactComponent as BG2 } from "~/images/lemonade-bg-2.svg";
import { ReactComponent as BG3 } from "~/images/lemonade-bg-3.svg";

interface HasKids {
  children: ReactNode;
}

interface LemonadeTextProps extends HasKids {
  className?: string
}

interface QAProps {
  longAnswer: string;
  question: string;
  shortAnswer: string;
}

interface NumberInfoProps {
  info: string;
  num: string;
}

const LemonadeH2 = ({ children, className }: LemonadeTextProps) => {
  const classList: string = className
    ? `text-4xl text-lemonade-yellow-700 ${className}`
    : "text-4xl text-lemonade-yellow-700"

  return (
    <h3 className={classList} style={{ fontFamily: "Yeseva One" }}>{ children }</h3>
  );
};

const LemonadeH3 = ({ children, className }: LemonadeTextProps) => {
  const classList: string = className
    ? `text-2xl text-lemonade-yellow-700 ${className}`
    : "text-2xl text-lemonade-yellow-700"

  return (
    <h3 className={classList} style={{ fontFamily: "Yeseva One" }}>{ children }</h3>
  );
};

const Nav = () => (
  <nav className="bg-lemonade-yellow-400 text-white w-full md:bg-transparent md:absolute md:left-0 md:top-0 overflow-visible z-40">
    <div className="flex flex-wrap flex-col text-center md:flex-row md:flex-nowrap md:justify-end md:text-left">
      <a className="text-white m-5" href="#">Home</a>
      <a className="text-white m-5" href="#">Purchase</a>
      <a className="text-white m-5" href="#">FAQs</a>
      <a className="text-white m-5" href="#">Conntact</a>
      <a className="text-white m-5" href="#">Research</a>
      <a className="text-white m-5" href="#">About</a>
    </div>
    <div className="py-4 w-full px-10 md:px-0 md:max-w-md md:ml-32 md:mt-40 md:w-auto">
      <h1 className="text-4xl text-center md:text-left" style={{ fontFamily: "Roboto" }}>Lose weight fast with the <span className="font-bold">Lemonade Diet Cleanse</span></h1>
      <span className="text-lemonade-yellow-200 text-center w-full block">sponsored site</span>
    </div>
  </nav>
);

const TopBackground = () => (
  <div className="md:absolute md:top-0 md:left-0 md:z-30 flex overflow-hidden w-full">
    <Nav />
    <div className="hidden md:block w-full">
      <BG1/>
    </div>
    <div className="z-30 hidden md:block w-full">
      <BG1 style={{ transform: "scale(-1, 1)" }}/>
    </div>
  </div>
);

const SecondBackground = () => (
  <div className="hidden md:flex absolute top-1.5 left-0 z-20 overflow-hidden w-full">
    <div className="w-full">
      <BG2 />
    </div>
    <div className="w-full">
      <BG2 style={{ transform: "scale(-1, 1)" }}/>
    </div>
  </div>
);

const ThirdBackground = () => (
  <div className="hidden md:flex absolute top-16 left-0 z-10 overflow-hidden w-full">
    <div className="w-full">
      <BG3 />
    </div>
    <div className="w-full">
      <BG3 style={{ transform: "scale(-1, 1)" }}/>
    </div>
  </div>
);

const Header = () => (
  <div className="md:relative md:h-550">
    <TopBackground />
    <SecondBackground />
    <ThirdBackground />
    <StaticImage
      alt="Full lemon"
      className="hidden md:block absolute z-0"
      style={{ left: "760px", top: "170px" }}
      src="../../images/full-lemon.jpg"
      placeholder="blurred"
      layout="fixed"
      width={564}
      height={393}
    />
  </div>
);

const WhyCleanse = () => (
  <div className="flex flex-wrap md:flex-nowrap mt-10">
    <div className="flex ml-5 md:ml-0 md:w-1/4">
      <LemonadeH2 className="self-center mx-auto">Why cleanse?</LemonadeH2>
    </div>
    <div className="flex flex-wrap justify-center mt-5 md:mt-0 md:w-3/4 md:grid md:grid-flow-row md:grid-cols-3 md:gap-4 lg:gap-2 text-2xl text-lemonade-gray-800" style={{ fontFamily: "Yeseva One" }}>
      <div className="bg-lemonade-yellow-200 m-3 p-5 rounded-lg shadow w-48 h-48 text-center flex items-center m-2">Heal your cells</div>
      <div className="bg-lemonade-yellow-200 m-3 p-5 rounded-lg shadow w-48 h-48 text-center flex items-center m-2">Improve your energy</div>
      <div className="bg-lemonade-yellow-200 m-3 p-5 rounded-lg shadow w-48 h-48 text-center flex items-center m-2">Rest and repair the gut</div>
      <div className="bg-lemonade-yellow-200 m-3 p-5 rounded-lg shadow w-48 h-48 text-center flex items-center m-2">Eliminate harmful foods</div>
      <div className="bg-lemonade-yellow-200 m-3 p-5 rounded-lg shadow w-48 h-48 text-center flex items-center m-2">Get rid of excess salt in your joints</div>
      <div className="bg-lemonade-yellow-200 m-3 p-5 rounded-lg shadow w-48 h-48 text-center flex items-center m-2">Clean your bloodstream</div>
    </div>
  </div>
);

const WhatsInTheLemonade = () => (
  <div className="flex flex-wrap md:flex-nowrap justify-items-center bg-lemonade-yellow-400 mt-10">
    <div className="flex justify-center bg-white md:justify-left md:ml-32 w-full md:w-auto">
      <StaticImage
        alt="Lemon slice"
        src="../../images/lemon-slice.jpg"
        placeholder="blurred"
        layout="fixed"
        width={309}
        height={223}
      />
    </div>
    <div className="ml-10 py-3">
      <LemonadeH2>What's in the lemonade?</LemonadeH2>
      <p>Fresh lemon juice - preferably organic</p>
      <p>Maple syrup</p>
      <p>Cayenne pepper</p>
      <p>Pure water</p>
    </div>
  </div>
);

const TellMeMoreHeader = () => (
  <>
    <LemonadeH2 className="mx-auto w-full text-center">Tell me more about the cleanse!</LemonadeH2>
    <div className="w-full flex justify-center">
      <span className="my-6 border-2 border-lemonade-yellow-700 rounded w-32" style={{ height: "2px" }} />
    </div>
  </>
);

const WhatDoINeedToDo = () => (
  <div className="w-full">
    <div>
      <LemonadeH3>What do I need to do?</LemonadeH3>
      <ol>
        <li>
          Drink 6-12 glasses of the lemonade diet drink whenever you are
          hungry.
        </li>
        <li>Take a laxative before bed.</li>
        <li>
          In the morning, drink a quart of salt water to flush the body.
        </li>
      </ol>
    </div>
  </div>
);

const ShortAnswer = ({ children }: HasKids) => (
  <span className="font-bold">{children}</span>
);

const QA = ({ question, shortAnswer, longAnswer }: QAProps) => {
  return (
    <div className="mt-5 w-full">
      <LemonadeH3>{question}</LemonadeH3>
      <p className="mt-5"><ShortAnswer>{shortAnswer}</ShortAnswer>&nbsp;{longAnswer}</p>
    </div>
  );
};

const WillILoseWeight = () => (
  <QA
    longAnswer="People lose an average of 3-4 pounds during the first 3 days of juice cleansing."
    shortAnswer="Yes you will!"
    question="Will I lose weight?"
  />
);

const WillIBeHungry = () => (
  <QA
    longAnswer="During the juice cleanse, people don't even want to drink all the juice they should!"
    shortAnswer="No, not at all!"
    question="Will I be hungry?"
  />
);

const ShouldEveryone = () => (
  <QA
    longAnswer="The CDC has shown that we all have an average of 153 chemicals in us. Even babies in the womb have accumulated toxic chemicals."
    shortAnswer="YES!"
    question="Should everyone do the cleanse?"
  />
);

const CanEveryone = () => (
  <QA
    longAnswer="Everyone who is reasonably healthy can do the cleanse. People who are pregnant or have life-threatening conditions may not be able to. Everyone should check with their healthcare provider. Everyone can do the 4-day vegan diet after the 3-day juice phase."
    shortAnswer="Yes!"
    question="Can everyone do the cleanse?"
  />
);

const Shop = () => (
  <div
    className="w-full bg-lemonade-yellow-500 text-white p-5 text-2xl font-bold flex items-center mt-10 lg:mt-0 lg:absolute lg:rounded-l lg:w-220 lg:h-162 lg:-ml-210 lg:top-32"
    style={{
      fontFamily: "Abhaya Libre",
      left: "100%",
    }}
  >
    Shop our online store NOW!
  </div>
);

const TellMeMore = () => (
  <div className="relative">
    <div className="flex flex-wrap mt-10 px-3 md:px-32">
      <TellMeMoreHeader />
      <WhatDoINeedToDo />
      <WillILoseWeight />
      <WillIBeHungry />
      <ShouldEveryone />
      <CanEveryone />
    </div>
    <Shop />
  </div>
);

const NumberInfo = ({ info, num }: NumberInfoProps) => (
  <div className="flex flex-wrap sm:flex-nowrap sm:justify-between sm:items-center w-full mt-5">
    <span className="text-2xl text-lemonade-yellow-700 font-bold sm:px-10 sm:w-1/3" style={{ fontFamily: "Roboto" }}>{num}</span>
    <p className="sm:w-2/3">{info}</p>
  </div>
);

const LookAtTheNumbers = () => (
  <div className="flex flex-wrap px-5 md:px-32 justify-center mt-10">
    <LemonadeH2 className="mx-auto w-full text-center">Look at the numbers</LemonadeH2>
    <div className="flex flex-wrap max-w-2xl rounded-lg shadow-lg bg-lemonade-yellow-200 py-5 px-12 mt-10">
      <NumberInfo
        num="60"
        info="percent of the immune system that lives in the gut."
      />
      <NumberInfo
        num="20"
        info="number of plant species that comprise 90% of the American diet."
      />
      <NumberInfo
        num="20,000"
        info="number of edible plant species on Earth"
      />
      <NumberInfo
        num="2"
        info="number of years that arthritis patients in a Scandinavian study felt better after a one-week cleanse followed by 3 weeks on a vegan diet."
      />
    </div>
  </div>
);

const Footer = () => (
  <div className="flex flex-wrap bg-lemonade-yellow-500 w-full py-16 mt-10 text-white">
    <p className="text-center w-full font-semibold">"In just a few short days, you can have your life back!"</p>
    <p className="text-center w-full">- J. Black, MD</p>
  </div>
);

const LemonDiet = () => (
  <div className="overflow-hidden">
    <Header />
    <WhyCleanse />
    <WhatsInTheLemonade />
    <TellMeMore />
    <LookAtTheNumbers />
    <Footer />
  </div>
);

export default LemonDiet;
